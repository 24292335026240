<template>
  <div class="w-100 mb-4">
    <Header />
    <Form @saved="loadData" :isProfile="true" />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Form from "../components/Form.vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  components: { Header, Form },
  computed: {
    ...mapGetters("auth", ["GetUser"]),
  },
  methods: {
    ...mapActions("users", ["ActionSetUser"]),
    async loadData(id) {},
  },
  async created() {
    const userProfile = Object.assign({}, this.GetUser);
    this.ActionSetUser(userProfile);
  },
};
</script>

<style></style>
